/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
.navigation__homelink {
  float: left;
  margin-right: 6px;
  background: #fff;
  text-transform: uppercase;
  font-size: 18px;
  width: 200px;
  line-height: 20px;
  padding: 15px 10px;
  text-align: center;
  box-sizing: border-box;
}
.navigation__homelink:hover,
.navigation__homelink:focus {
  background: #c40d0e;
  color: #fff;
}
@media (max-width: 1024px) {
  .navigation__homelink {
    width: calc((100vw - (4 * 6px)) / 5);
    font-size: 14px;
    line-height: 1.42857143;
  }
}
/* LEVEL 1 */
div.sub1 {
  float: left;
  max-width: 904px;
}
.sub1 > .item {
  margin-right: 6px;
  margin-bottom: 6px;
  position: relative;
}
.sub1 > .item.exit {
  margin-right: 0;
}
div.sub1 > .item > .menu {
  background: #fff;
  text-transform: uppercase;
  font-size: 18px;
  width: 200px;
  line-height: 20px;
  padding: 15px 10px;
  text-align: center;
  box-sizing: border-box;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  background: #c40d0e;
  color: #fff;
}
div.sub1 > .item > .menu.path {
  background: #c40d0e;
  color: #fff;
}
@media only screen and (max-width: 1024px) {
  div.sub1 > .item > .menu {
    width: calc((100vw - (4 * 6px)) / 5);
    font-size: 14px;
    line-height: 1.42857143;
  }
}
div.sub2 {
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 100%;
  background: #fff;
  padding: 10px 0;
  display: none;
  z-index: 5;
}
div.sub2 > .item {
  width: 100%;
}
div.sub2 > .item:hover > .menu,
div.sub2 > .item:focus > .menu {
  color: #000;
  background: none;
}
div.sub2 .menu {
  text-transform: none;
  padding: 4px 10px;
  text-align: left;
  background: none;
  color: #c40d0e;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus,
div.sub2 .menu.path {
  color: #000;
  background: none;
}
.cbe--edit-navigation div.sub1 .item.path div.sub2,
div.sub1 .item:hover div.sub2 {
  display: block;
}
@media only screen and (max-width: 1024px) {
  div.sub2 .menu {
    font-size: 14px;
    line-height: 1.42857143;
  }
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
#home {
  right: 3%;
  top: 70px;
  width: 350px;
  padding: 10px;
  box-sizing: border-box;
  background-image: radial-gradient(50% 50% ellipse, #FFFFFF5E, #FFFFFF00);
}
#view div.seam:hover div.pict a:after {
  top: 0;
  height: 100%;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0.375%;
  margin-left: 0.375%;
}
.area .part,
.area > .grid table {
  margin-right: 0.375%;
  margin-left: 0.375%;
  width: 99.25%;
}
.area .tiny {
  width: 49.25%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 0.75%;
  margin-left: 0.75%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 98.5%;
}
.area > .slim .tiny {
  width: 48.5%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 0.375%;
  margin-left: 0.375%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 0.375%;
  margin-left: 0.375%;
  width: 99.25%;
}
.cb-layout2 .main .tiny {
  width: 24.25%;
}
.cb-layout2 .main > .slim {
  width: 25%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 1.5%;
  margin-left: 1.5%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 97%;
}
.cb-layout2 .main > .slim .tiny {
  width: 97%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 100.75566751%;
  margin-left: -0.37783375%;
}
.cb-layout2 .main {
  width: 100.75566751%;
  margin-left: -0.37783375%;
}
.cb-layout1 div.main div.seam,
.cb-layout3 div.main div.seam {
  margin-left: 0.375%;
  margin-right: 0.375%;
}
.cb-layout1 div.main div.seam.wide,
.cb-layout3 div.main div.seam.wide {
  width: 99.25%;
}
.cb-layout1 div.main div.seam.slim,
.cb-layout3 div.main div.seam.slim {
  width: 49.25%;
}
.cb-layout2 div.main div.seam {
  margin-left: 0.375%;
  margin-right: 0.375%;
}
.cb-layout2 div.main div.seam.wide {
  width: 99.25%;
}
.cb-layout2 div.main div.seam.slim {
  width: 24.25%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -625px;
  width: 1250px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #c40d0e;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #c40d0e;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #940a0b;
}
.mese .same {
  background: none;
}
/*# sourceMappingURL=./screen-large.css.map */